var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6f1e140ce0b0b88b6f1b81c09e2f40f849a323a1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { feedbackIntegration, init, replayIntegration } from '@sentry/nextjs';

const { isProd, isStaging } = require('./environments');

const integrations = {
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
        }),
        feedbackIntegration({
            showBranding: false,
            colorScheme: 'dark',
            buttonLabel: 'Feedback',
            submitButtonLabel: 'Send Feedback',
            formTitle: 'Send Feedback',
            messagePlaceholder: '',
            messageLabel: 'Message',
            autoInject: false,
        }),
    ],
};

init({
    enabled: isProd || isStaging,
    environment: process.env.appEnv,
    dsn: 'https://3473a03eb9aa24bb9d29b682a8046259@o4506192105635840.ingest.sentry.io/4506192107143168',

    tracesSampleRate: 0,

    debug: false,
    ...integrations,
});
