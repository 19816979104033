import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'regenerator-runtime';

import { UserAuthenticationProvider } from '@/contexts/user-authentication';
import { persistor, store } from '@/store/store';
import '@/styles/globals.css';
import { COLOR_MODE_KEY, theme } from '@/theme';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const manager = createLocalStorageManager(COLOR_MODE_KEY);

export default function App({ Component, pageProps }: AppProps) {
    const [queryClient] = useState(new QueryClient());
    const router = useRouter();
    const isPublicPath = router.pathname.startsWith('/share') || router.pathname === '/';
    return (
        <>
            <Head>
                <title>Nexus</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme} colorModeManager={manager}>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <UserProvider>
                                <UserAuthenticationProvider isPublicPath={isPublicPath}>
                                    <Component {...pageProps} />
                                </UserAuthenticationProvider>
                            </UserProvider>
                        </PersistGate>
                    </Provider>
                </ChakraProvider>
            </QueryClientProvider>
        </>
    );
}
